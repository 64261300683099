<template>
	<div>
		<van-nav-bar title="合作方式" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<!-- 提供发票--企业 -->
		<div class="padding" v-if="choice==0">
			<div class="flex" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作方式</div>
			</div>
			<div class="list-strong padding mt">企业</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作双方的权利和义务</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>在平台提供真实的企业资质信息进行认证</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>平台将与您认证的企业进行服务费的结算</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>申请服务费时，请先按照要求开具发票，开票主体与您认证的企业信息一致</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>由您的企业自行处理收入的申报和税费缴纳</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作条件</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>已有稳定的企业，不会发生变更</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>您的企业支持开具“服务费”类型的增值税普通发票或者专用发票</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">服务费申请方式</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>"服务费"经平台审核通过后，开具发票并邮寄发票</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">服务费发放时间</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>平台收到发票核对无误即发放至您企业的对公账户</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">综合手续费</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>无</div>
			</div>
			<div class="list-btn" :class="down?'bg-orange':'bg-gray'" @click="onConfirm" v-if="state==0">我已了解，确定选择<span v-if="time!=0">（{{time}}s）</span></div>
		</div>
		<!-- 提供发票--个体 -->
		<div class="padding" v-if="choice==1">
			<div class="flex" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作方式</div>
			</div>
			<div class="list-strong padding mt">个体工商户</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作条件</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>已有稳定的个体工商户，不会发生变更</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>您的个体工商户支持开具“服务费”类型的增值税普通或者专用发票</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">服务费申请方式</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>"服务费"经平台审核通过后，开具发票并邮寄发票</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">服务费发放时间</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>平台收到发票核对无误即发放至您个体工商户的对公账户</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">综合手续费</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>无</div>
			</div>
			<div class="list-btn" :class="down?'bg-orange':'bg-gray'" @click="onConfirm" v-if="state==0">我已了解，确定选择<span v-if="time!=0">（{{time}}s）</span></div>
			
		</div>
		<!-- 无法提供发票 -->
		<div class="padding" v-if="choice==2">
			<div class="flex" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作方式</div>
			</div>
			<div class="list-strong padding mt">个人</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">合作双方的权利和义务</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>在平台提供真实的个人信息进行认证</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>通过与平台合作的第三方机构进行签约</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>“服务费”平台审核通过后，由第三方机构代为报税纳税和发放服务费</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">月服务费申请额度</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>10000元</div>
			</div>
			<div class="flex mt" style="align-items: center;">
				<div class="list-left"></div>
				<div class="list-text">综合服务费</div>
				<div class="list-alert" @click="onExplain">
					<van-icon name="question-o" size="16"/>
				</div>
			</div>
			<div class="list-strong padding flex mt" style="align-items: center;">
				<div class="list-dian"></div>
				<div>月服务费申请总额小于等于1000时无综合手续费，月申请服务费总额 大于1000时按月服务费申请总额的7%收取</div>
			</div>
			
			
			<div class="list-btn" :class="down?'bg-orange':'bg-gray'" @click="onConfirm" v-if="state==0">我已了解，确定选择<span v-if="time!=0">（{{time}}s）</span></div>
		</div>
		
		<div class="padding"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				choice:0,//0==企业，1==个人  2==无法提供发票
				time:10,
				down:false,
				state:0,
				login_token: localStorage.getItem("login_token"),
				openid:null,
			}
		},
		mounted() {
			var _this=this;
			_this.choice=_this.$route.query.choice;//判断来路
			if(_this.$route.query.state){
				_this.state=_this.$route.query.state
			}
			//倒计时
			var downTime=setInterval(function(){
				_this.time--;
				if(_this.time<=0){
					clearInterval(downTime)
					_this.down=true
				}
			},1000)
			
			
		},
		methods: {
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			//综合手续费弹出说明
			onExplain(){
				this.$dialog.confirm({
					title:"综合手续费",
					message:"包含税费、手续费及其他相关费用",
					showCancelButton:false
				})
			},
			//点击底部按钮
			onConfirm(){
				var _this=this;
				if(_this.down){
					_this.$router.push({
						path:"/realname/cooperation/authentication",
						query:{
							choice:_this.choice
						}
					})
				}
			},
		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped="scoped">
	.list-alert{
		padding-left: 0.1rem;
		padding-top: 0.16rem;
		color: #FFD6D6;
	}
	.bg-orange{
		background-color: #F15223;
	}
	.bg-gray{
		background-color: #E2E2E2;
	}
	.list-btn{
		width: 5.86rem;
		height: 0.88rem;
		border-radius: 9999998px;
		line-height: 0.88rem;
		text-align: center;
		color: #FFFFFF;
		margin: 0 auto;
		margin-top: 1.08rem;
	}
	.list-dian{
		width: 0.08rem;
		height: 0.08rem;
		background-color: #D7B6B9;
		border-radius: 50%;
		margin-right: 0.2rem;
	}
	.mt{
		margin-top: 0.3rem;
	}
	.list-strong{
		background-color: #FEF1F2;
		border-radius: 0.2rem;
		color: #D7B6B9;
		font-size: 0.26rem;
	}
	.list-text{
		font-size: 0.3rem;
		margin-left: 0.2rem;
	}
	.list-left{
		width: 0.06rem;
		height: 0.2rem;
		background-color: #F15223;
	}
	.padding{
		padding: 0.3rem;
	}
	.flex{
		display: flex;
	}
	
</style>
